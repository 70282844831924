<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <FormulateForm name="creator-personal-info-form" @submit="submitCreatorInfo">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Descrição</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12">
                    <div class="form-field">
                      <FormulateInput type="textarea" rows="9" v-model="creatorBio" name="about" validation="required" placeholder="" label="Sobre" />
                    </div>
                  </div>
                  <!--<div class="reserved-area-form-column col-12">
                    <div class="form-field input-file">
                      <label class="d-flex flex-column">
                        <FormulateInput type="image" name="about-image" validation="mime:image/jpeg,image/png,image/gif" multiple />
                        <div class="input-file-content d-flex justify-content-between align-items-center">
                          <p class="input-file-title">Adicionar Imagem (Max. 3 Imagens)</p>
                          <button type="button" class="input-file-btn">
                            <plusIcon/>
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import plusIcon from "@/components/svg/plus.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-personal-page",
  data() {
    return {
      errors: [],
      creatorBio: this.$root.userInfo.bio,
    }
  },
  components: {
    reservedAreaMenu,
    plusIcon
  },
  methods: {
    submitCreatorInfo() {
      let authKey = window.$cookies.get("authKey");

      const requestBody = {
        bio: this.creatorBio,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'authors', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            this.$root.userInfo.bio = this.creatorBio;
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = e.response.data.errors.password[0];
          });
    }
  }
}
</script>